import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import React from "react";
import { Component } from "react";
import { MAP_KEY } from "../../config/build.config";
import logoImg from "../../assets/image/logo.png";
export class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
    };
  }
  render() {
    const onMarkerClick = (props, marker) =>
      this.setState({
        activeMarker: marker,
        selectedPlace: props,
        showingInfoWindow: true,
      });

    const onInfoWindowClose = () =>
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={{ width: "100%", height: "100%" }}
        containerStyle={{ width: "100%", height: "100%" }}
        initialCenter={{
          lat: 16.80659,
          lng: 96.147488,
        }}
      >
        <Marker
          title={"Oriental Vigour"}
          name={"OVMyanmar"}
          position={{ lat: 16.80659, lng: 96.147488 }}
          onClick={onMarkerClick}
        />
        <InfoWindow
          marker={this.state.activeMarker}
          onClose={onInfoWindowClose}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <img src={logoImg} alt="logo" className=" w-20 h-20" />
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: MAP_KEY,
})(GoogleMap);
