import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import HeaderSection from "./components/HeaderSection/HeaderSection";
import NavBar from "./components/NavBar/NavBar";
import OurProduct from "./components/OurProduct/OurProduct";
// import OurService from "./components/OurService/OurServices";
import OurTeam from "./components/OurTeam/OurTeam";

function App() {
  return (
    <div className=" bg-bg-two bg-cover bg-no-repeat">
      <NavBar />
      <HeaderSection />
      <AboutUs />
      {/* <OurService /> */}
      <OurTeam />
      <OurProduct />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
