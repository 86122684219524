import OneLineLabel from "../Label/OneLineLabel";
import productSrc from "../../assets/image/ourProduct.png";
import smooveSrc from "../../assets/image/smoove.png";
import playStoreSrc from "../../assets/image/playStore.png";
import appStoreSrc from "../../assets/image/appStore.png";
import { Element } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";

export default function OurProduct() {
  return (
    <Element name="Our Product" className="pt-20 lg:pt-20">
      <div
        className="px-4 lg:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4"
        id="Our Product"
      >
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <div className="h-full flex justify-center items-center">
            <div className="">
              <img
                src={productSrc}
                className=" w-full lg:w-10/12 h-auto "
                alt="ourPorduct"
              />
              {/* <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <img
                  src={cameraSrc}
                  className="w-4/12 h-auto hidden lg:block absolute"
                  style={{ bottom: "-68px", right: "15px" }}
                  alt="aboutus_camera"
                />
              </ScrollAnimation> */}
            </div>
          </div>
        </ScrollAnimation>

        <div className="flex justify-center items-center h-full">
          <div>
            <div>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <OneLineLabel text="our product" />
                <h1 className="text-3xl my-4">
                  <span className=" text-brand-yellow">smoove</span>
                </h1>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <p className="mt-4 mb-6 text-justify">
                  smoove, a travel app appealing to the essence of travelling
                  with its tourist attractions and knowledgeable articles about
                  Myanmar. Having the essential features for travelling, i.e.
                  researching the accommodation, finding restaurants and
                  calculating the estimated cost for the whole trip, make
                  travelling easier. In case of an emergency, the phone numbers
                  of emergency lines are also included. Truly, an ideal app for
                  travelling in Myanmar.
                </p>
              </ScrollAnimation>
            </div>

            <div className="mb-6 flex flex-wrap">
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="mt-2">
                  <a
                    href="https://www.smoovetravel.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={smooveSrc}
                      className="w-auto h-20 mr-3"
                      alt="trophy"
                    />
                  </a>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="mt-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.ov.smoove&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img"
                  >
                    <img
                      src={playStoreSrc}
                      className="h-20 w-auto mr-3"
                      alt="search"
                    />
                  </a>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="mt-2">
                  <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/smoove/id1660198381">
                    <img
                      src={appStoreSrc}
                      className="h-20 w-auto mr-3"
                      alt="search"
                    />
                  </a>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}
