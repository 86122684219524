import OneLineLabel from "../Label/OneLineLabel";
import aboutSrc from "../../assets/image/about.jpeg";
// import trophySrc from "../../assets/image/trophy.png";
// import searchSrc from "../../assets/image/search.png";
import { Element } from "react-scroll";

export default function AboutUs() {
  return (
    <Element>
      <div
        className="px-4 lg:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4"
        id="About Us"
      >
        <div className="h-full flex justify-center items-center animate__animated animate__fadeInUp">
          <img
            src={aboutSrc}
            className=" w-full lg:w-10/12 h-auto shadow-card"
            alt="aboutUs"
          />
        </div>

        <div className="flex justify-center items-center h-full animate__animated animate__fadeInUp">
          <div>
            <OneLineLabel text="our goals" />
            <div>
              <div className="my-8 md:my-0 lg:my-0">
                <h1 className="text-3xl my-4">
                  About&nbsp;
                  <span className=" text-brand-yellow">Us</span>
                </h1>
              </div>
              <p className="mt-4 text-justify">
                We founded our business in 2021 to start our IT business,
                development and consulting services. With outcomes of the well
                structured harmony team menbers, Oriental Vigour aims to help
                Myanmar traveling and business sector with digital technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}
