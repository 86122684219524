import OneLineLabel from "../Label/OneLineLabel";
import TeamCard from "./TeamCard/TeamCard";
import { Element } from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import teamImgSrc1 from "../../assets/image/team-img-1.jpg";
import teamImgSrc2 from "../../assets/image/team-img-2.jpg";

export default function OurTeam() {
  return (
    <Element name="Our Team">
      <div className="px-4 lg:px-20 pt-20 lg:pt-32" id="our_team">
        <div className=" grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-6">
          <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <div>
              <OneLineLabel text="SPECIALISTS" />
              <div>
                <div className="my-8 md:my-0 lg:my-0">
                  <h1 className="text-3xl my-4">
                    The&nbsp;
                    <span className=" text-brand-yellow">Best</span> Team
                  </h1>
                </div>
                <p className="mt-4 text-justify">
                  Our company consists of two teams, the IT team and Creative
                  team members. The combination of IT Specialists and Passionate
                  Art Creative Team Members makes our products not only
                  efficacious but also future partners in our everyday lives.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={100}>
            <div>
              <TeamCard
                src={teamImgSrc1}
                name="Art Creative Team"
                position=""
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={200}>
            <div>
              <TeamCard src={teamImgSrc2} name="IT Team" position="" />
            </div>
          </ScrollAnimation>
          {/* <ScrollAnimation animateIn="fadeInUp" animateOnce delay={300}>
            <div>
              <TeamCard
                src={ourTeamData[2].src}
                name={ourTeamData[2].name}
                position={ourTeamData[2].position}
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={400}>
            <div>
              <TeamCard
                src={ourTeamData[3].src}
                name={ourTeamData[3].name}
                position={ourTeamData[3].position}
              />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce delay={500}>
            <div className=" flex justify-center items-center ">
              <button type="button" className={styles.seeAll}>
                All Team
              </button>
            </div>
          </ScrollAnimation> */}
        </div>
      </div>
    </Element>
  );
}
