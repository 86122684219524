import OneLineLabel from "../Label/OneLineLabel";
import logoSrc from "../../assets/image/logo.png";
import { Element } from "react-scroll";

export default function HeaderSection() {
  return (
    <Element name="Home">
      <div
        className="px-4 pt-20 lg:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2"
        id="home"
      >
        <div className="h-auto pl-0 lg:max-h-max flex justify-center items-center order-2 lg:order-1 ">
          <div className="animate__animated animate__fadeInLeft">
            <div>
              <div className="mt-4">
                <OneLineLabel text="Our Mission" />
              </div>
              <h1 className="text-3xl my-4">
                Mission&nbsp;
                <span className=" text-brand-yellow">Statement</span>
              </h1>
            </div>
            <div>
              <p className="text-justify">
                In today's modern era, technology is a necessity for everyday
                life. We aspire to offer our clients IT services and IT
                consulting to expand their businesses further. Inspired by the
                rapidly advancing technology, our vision has always been to
                develop handy lifestyle applications that would help people's
                lives lessen their trouble.
              </p>
            </div>
          </div>
        </div>
        <div className=" h-auto lg:h-80 md:max-h-full  flex justify-center items-center order-1 md:order-2 animate__animated animate__fadeInRight">
          <img src={logoSrc} className=" w-2/4 h-auto" alt="logo"></img>
        </div>
      </div>
    </Element>
  );
}
