import classNames from "classnames";
import styles from "./TeamCard.module.scss";
import {
  GrFacebookOption,
  GrInstagram,
  GrTwitter,
  GrYoutube,
} from "react-icons/gr";

export default function TeamCard({ src, name, position }) {
  return (
    <div className={classNames("w-50 h-50 shadow-card", styles.container)}>
      <img src={src} className="w-full h-auto" alt="team" />
      <div className={styles.overlay}>
        <div className="flex justify-center items-center w-full h-full text-white text-center">
          <div>
            <h1 className=" font-bold ">{name}</h1>
            <span className=" text-gray-500 text-sm">{position}</span>
            <div className={classNames(styles.socialDiv)}></div>
            <div className="flex justify-center  mt-8">
              <div className="flex">
                <span className=" text-lg mr-2">
                  <a href="/#">
                    <GrFacebookOption />
                  </a>
                </span>
                <span className=" text-lg mr-2">
                  <a href="/#">
                    <GrInstagram />
                  </a>
                </span>
                <span className=" text-lg mr-2">
                  <a href="/#">
                    <GrTwitter />
                  </a>
                </span>
                <span className=" text-lg">
                  <a href="/#">
                    <GrYoutube />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
