import logoSrc from "../../assets/image/logo.png";
import classNames from "classnames";
import { Link } from "react-scroll";

export default function Footer() {
  return (
    <footer className="p-4 bg-brand-blue sm:p-6 mt-4 lg:px-20">
      <div className="md:flex md:justify-between pt-10">
        <div className="flex items-center justify-center mb-6 md:mb-0 w-60">
          <a href="/" className="bg-brand-white rounded-md">
            <img src={logoSrc} className="mr-3" alt="Logo" />
          </a>
        </div>
        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div>
            <h2 className="mb-6 text-sm font-semibold text-brand-white uppercase">
              Resources
            </h2>
            <ul className="text-gray-600">
              <li className="mb-4 hover:cursor-pointer">
                <Link
                  activeClass="active"
                  className={classNames(
                    "text-md font-bold block py-2 rounded hover:bg-gray-100 hover:bg-transparent md:border-0 hover:text-brand-yellow md:p-0 text-brand-white"
                  )}
                  to="Home"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </li>

              <li className="mb-4 hover:cursor-pointer">
                <Link
                  activeClass="active"
                  className={classNames(
                    "text-md font-bold block py-2 rounded hover:bg-gray-100 hover:bg-transparent md:border-0 hover:text-brand-yellow md:p-0 text-brand-white"
                  )}
                  to="About Us"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  About Us
                </Link>
              </li>
              <li className="mb-4 hover:cursor-pointer">
                <Link
                  activeClass="active"
                  className={classNames(
                    "text-md font-bold block py-2 rounded hover:bg-gray-100 hover:bg-transparent md:border-0 hover:text-brand-yellow p-0 text-brand-white"
                  )}
                  to="Contact Us"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="mt-11">
            {/* <h2 className="mb-6 text-sm font-semibold text-gray-600 uppercase">
              Follow us
            </h2> */}
            <ul className="text-gray-600 ">
              <li className="mb-4 hover:cursor-pointer">
                <Link
                  activeClass="active"
                  className={classNames(
                    "text-md font-bold block py-2 rounded hover:bg-gray-100 hover:bg-transparent md:border-0 hover:text-brand-yellow md:p-0 text-brand-white"
                  )}
                  to="Our Team"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Our Team
                </Link>
              </li>

              <li className="mb-4 hover:cursor-pointer">
                <Link
                  activeClass="active"
                  className={classNames(
                    "text-md font-bold block py-2 rounded hover:bg-gray-100 hover:bg-transparent md:border-0 hover:text-brand-yellow md:p-0 text-brand-white"
                  )}
                  to="Our Product"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Our Product
                </Link>
              </li>
            </ul>
          </div>
          {/* <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-600 uppercase">
              Legal
            </h2>
            <ul className="text-gray-600 ">
              <li className="mb-4">
                <a href="#" className="hover:underline">
                Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <hr className="my-6 border-gray-600 sm:mx-auto  lg:my-8" />
      <div className="text-center">
        <span className="text-sm sm:text-center text-brand-white">
          Copyright © Oriental Vigour 2023
        </span>
        {/* <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
          <a
            href="http://www.facebook.com/"
            className="text-gray-500 hover:text-white "
          >
            <span>
              <BsFacebook />
            </span>
            <span className="sr-only">Facebook page</span>
          </a>
          <a
            href="http://www.instagram.com/"
            className="text-gray-500 hover:text-white "
          >
            <span>
              <BsInstagram />
            </span>
            <span className="sr-only">Instagram page</span>
          </a>
          <a
            href="http://www.twitter.com/"
            className="text-gray-500 hover:text-white "
          >
            <span>
              <BsTwitter />
            </span>
            <span className="sr-only">Twitter page</span>
          </a>
          <a
            href="https://gitlab.com/"
            className="text-gray-500 hover:text-white "
          >
            <span>
              <FaGitlab />
            </span>
            <span className="sr-only">Gitlab account</span>
          </a>
        </div> */}
      </div>
    </footer>
  );
}
