import React, { useState } from "react";
import logoSrc from "../../assets/image/nav_logo.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"
import classNames from "classnames";
import { Link } from "react-scroll";

const NavBar = () => {
  const [isToggle, setIsToggle] = useState(false);

  const LinkBtn = ({ label, onClick }) => {
    return (
      <li className=" cursor-pointer">
        <Link
          activeClass="active"
          className={classNames(
            "text-md font-bold block py-2 pr-4 pl-3 rounded hover:bg-brand-white md:border-0 md:hover:bg-transparent hover:text-brand-yellow md:p-0 text-brand-white "
          )}
          to={label}
          spy={true}
          smooth={true}
          duration={500}
          onClick={onClick}
        >
          {label}
        </Link>
      </li>
    );
  };
  return (
    <nav className="bg-brand-blue border-gray-200 px-4 py-2.5 rounded fixed  z-40 w-full mb-2 top-0 left-0 right-0 lg:px-20" >
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" className="flex items-center bg-brand-white rounded">
          <img src={logoSrc} className="w-10 h-9 md:h-10" alt=" Logo" />
        </a>
        <button
          type="button"
          onClick={() => setIsToggle(!isToggle)}
          className="inline-flex items-center p-2 ml-3 text-brand-white rounded-lg md:hidden focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <span className="sr-only">Open main menu</span>
          {isToggle ? (
            <span>
              <AiOutlineClose className="text-2xl" />
            </span>
          ) : (
            <span>
              <AiOutlineMenu className="text-2xl"/>
            </span>
          )}
        </button>
        <div className="hidden md:block md:w-auto">
          <ul className="flex flex-col pt-4 pb-4 mt-4 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 bg-brand-blue">
            <LinkBtn label="Home" />
            <LinkBtn label="About Us" />
            <LinkBtn label="Our Team" />
            <LinkBtn label="Our Product" />
            <LinkBtn label="Contact Us" />
          </ul>
        </div>
        <div className={isToggle ? "w-full" : "hidden"}>
          <ul className="flex flex-col pt-4 mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 bg-brand-blue">
            <LinkBtn label="Home" onClick={() => setIsToggle(!isToggle)} />
            <LinkBtn label="About Us" onClick={() => setIsToggle(!isToggle)} />

            <LinkBtn label="Our Team" onClick={() => setIsToggle(!isToggle)} />
            <LinkBtn
              label="Our Product"
              onClick={() => setIsToggle(!isToggle)}
            />
            <LinkBtn
              label="Contact Us"
              onClick={() => setIsToggle(!isToggle)}
            />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
